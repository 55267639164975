import { FC } from 'react';

import { Icon } from '@vakantiesnl/components/src/__LEGACY__/atoms/Icon';

import styles from './ScrollLabel.module.css';

type Props = {
	title: string;
};

const ScrollLabel: FC<Props> = ({ title }) => (
	<span className={styles.scrollLabel}>
		<a className={styles.scrollLabelTitle}>{title}</a>
		<Icon className={styles.scrollIcon} type="vaknlSleekArrowRight" />
	</span>
);

export { ScrollLabel };
