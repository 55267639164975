import { FC, memo, MouseEventHandler, ReactNode } from 'react';

import { Icon, IconTypes } from '@vakantiesnl/components/src/__LEGACY__/atoms/Icon';
import { Link, LinkProps } from '@vakantiesnl/components/src/__LEGACY__/atoms/Link';
import { useIsActivePage } from '@vakantiesnl/services/src/hooks/usePageState';
import { MicroCopy } from '@vakantiesnl/types';
import classNames from 'classnames';

import css from './MenuItem.module.css';

export type MenuItemProps = {
	isActive?: boolean;
	isExternal?: boolean;
	isToggle?: boolean;
	href?: string;
	microCopies: MicroCopy;
	onClick?: MouseEventHandler;
	target?: '_blank' | '_self';
	toggled: boolean;
	title?: string;
	menuItemClassName?: string;
	icon?: IconTypes;
	iconPosition?: 'after' | 'before';
	iconClassName?: string;
	dataCy?: string;
};

type MenuItemLinkProps = Omit<LinkProps, 'href'> & {
	children: ReactNode;
	href?: string;
	isExternal?: boolean;
	passHref?: boolean;
};

const MenuItemLink: FC<MenuItemLinkProps> = ({ children, href, ...props }) =>
	href ? (
		<Link href={href} passHref={true} {...props}>
			{children}
		</Link>
	) : (
		<>{children}</>
	);

const MenuItem: FC<MenuItemProps> = ({
	title,
	href,
	isExternal,
	isToggle,
	onClick,
	target,
	toggled,
	microCopies,
	menuItemClassName,
	icon,
	iconPosition,
	iconClassName,
	dataCy,
}) => {
	const isActive = useIsActivePage(href ? href : '');

	const externalLinkAttr = isExternal && {
		rel: 'noopener',
		target: target,
		href: href,
	};

	return (
		<li
			className={classNames(menuItemClassName, css.menuItem, 'trail-hover-wrapper', isToggle && css.isToggle)}
			data-toggled={toggled && 'toggled'}
			data-cy={dataCy}
		>
			<MenuItemLink href={href} legacyBehavior>
				<a onClick={onClick} className={classNames(isActive && css.isActive)} {...externalLinkAttr}>
					{icon && iconPosition === 'before' && <Icon type={icon} className={classNames(iconClassName)} />}
					{title && <span className={classNames(css.title, 'trail-hover')}>{title}</span>}
					{isToggle && (
						<div className={css.closeContainer}>
							<span className={css.closeText}>{microCopies['closeText']}</span>
							<Icon type="vaknlExit" className={css.toggleIcon} />
						</div>
					)}
					{icon && iconPosition === 'after' && <Icon type={icon} className={classNames(iconClassName)} />}
				</a>
			</MenuItemLink>
		</li>
	);
};

const memoizedMenuItem = memo(MenuItem);
export { memoizedMenuItem as MenuItem };
