import { deprecatedMakeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

const useStyles = deprecatedMakeStyles()((theme) => ({
	container: {
		display: 'table',
	},
	textAlignLeft: {
		textAlign: 'left',
	},
	textAlignCenter: {
		textAlign: 'center',
	},
	textAlignRight: {
		textAlign: 'right',
	},
	textAlignJustify: {
		textAlign: 'justify',
	},
	colorDefault: {
		color: theme.palette.grey[900],
	},
	colorError: {
		color: theme.palette.warning.dark,
	},
	link: {
		boxShadow: theme.shadows[2],
		textDecoration: 'none',
		color: theme.palette.common.black,
		cursor: 'pointer',

		'&:hover': {
			boxShadow: theme.shadows[2],
		},
	},
	paragraph: {
		fontSize: theme.typography.fontSize,
		lineHeight: '1.75',
		whiteSpace: 'pre-wrap',
		[theme.breakpoints.down('md')]: {
			fontSize: theme.typography.subtitle1.fontSize,
			lineHeight: '1.57',
		},
	},
}));

export default useStyles;
