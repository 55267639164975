import { Attributes, cloneElement, FC, isValidElement } from 'react';

import { isUndefined } from 'lodash';
import { default as NextLink, LinkProps as NextLinkProps } from 'next/link';
import { useRouter } from 'next/router';

import { findAndBuildMatchingRoute, getHref } from './link-utils';

export type LinkProps = Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof NextLinkProps> &
	NextLinkProps & {
		children?: React.ReactNode;
	} & React.RefAttributes<HTMLAnchorElement>;

const Link: FC<LinkProps> = ({ children, href, ...rest }) => {
	const matchingRoute = !isUndefined(href) ? findAndBuildMatchingRoute(href) : undefined;
	const router = useRouter();
	if (!router) {
		return <>{children}</>;
	}

	if (matchingRoute) {
		const builtHref = getHref(matchingRoute);
		return typeof builtHref === 'object' && !builtHref.pathname ? null : (
			<NextLink href={builtHref} as={href} {...rest}>
				{children}
			</NextLink>
		);
	}

	if (href) {
		if (typeof href === 'object' && !href.pathname) {
			return null;
		} else if (typeof href === 'string') {
			if (isValidElement(children) && children.type === 'a') {
				// Added to pass the href directly to child anchor elements. Otherwise an anchor would be wrapped inside another anchor.
				return cloneElement(children, { href } as Partial<unknown> & Attributes);
			}

			return (
				<a href={href} target={rest.target} className={rest.className}>
					{children}
				</a>
			);
		}
	}

	return null;
};

export { Link };
