/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// copied from https://github.com/vercel/next.js/blob/v9.5.2/packages/next/next-server/server/lib/path-match.ts
import * as pathToRegexp from 'path-to-regexp';

export { pathToRegexp };

const pathMatch = (customRoute = false) => {
	return (path: string) => {
		const keys: pathToRegexp.Key[] = [];
		const matcherOptions = {
			sensitive: false,
			delimiter: '/',
			...(customRoute ? { strict: true } : undefined),
			decode: decodeParam,
		};
		/* @ts-ignore */
		const matcherRegex = pathToRegexp.pathToRegexp(path, keys, matcherOptions);
		/* @ts-ignore */
		const matcher = pathToRegexp.regexpToFunction(matcherRegex, keys, matcherOptions);

		return (pathname: string | null | undefined, params?: never) => {
			const res = pathname == null ? false : matcher(pathname);
			if (!res) {
				return false;
			}

			if (customRoute) {
				for (const key of keys) {
					// unnamed params should be removed as they
					// are not allowed to be used in the destination
					if (typeof key.name === 'number') {
						delete (res.params as Record<number, unknown>)[key.name];
					}
				}
			}

			// @ts-ignore
			return { ...params, ...res.params };
		};
	};
};

const decodeParam = (param: string): string => {
	try {
		return decodeURIComponent(param);
	} catch (_) {
		const err: Error & { code?: string } = new Error('failed to decode param');
		err.code = 'DECODE_FAILED';
		throw err;
	}
};

export default pathMatch;
