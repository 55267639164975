import { ParsedUrlQuery } from 'querystring';
import { format, parse, UrlObject } from 'url';

import { baseRoutes } from '@vakantiesnl/services/src/route-params';

import pathMatch from './path-match';

export type MatchingRoute = {
	route: {
		source: string;
		destination: string;
		pathname: string;
	};
	query: string | null | ParsedUrlQuery;
};

const matchedBaseRoutes = baseRoutes.map((item) => ({ ...item, match: pathMatch(true)(item.source) }));

const normalizeRoute = (route: string): string => (route === '/' ? route : route.replace(/\/$/, ''));
const getPathname = (href: string | UrlObject): string | null => parse(normalizeRoute(href.toString())).pathname;
const getQuery = (href: string | UrlObject): Record<string, string> | null => {
	if (typeof href === 'string') {
		const splitHref = href.split('?');
		if (splitHref.length > 1) {
			const items: Record<string, string> = {};
			new URLSearchParams(splitHref[1]).forEach((value, key) => {
				items[key] = value;
			});
			return items;
		}
	}
	return null;
};

export const getHref = ({
	route,
	query,
}: MatchingRoute): { query: string | ParsedUrlQuery | null; pathname: string } => ({
	pathname: route.pathname,
	query,
});

export const findAndBuildMatchingRoute = (href: string | UrlObject): undefined | MatchingRoute => {
	const normalizedHref = format(href);
	if (!normalizedHref.startsWith('/')) {
		return undefined;
	}

	const pathname = getPathname(normalizedHref);
	const asQuery = getQuery(normalizedHref);

	for (const route of matchedBaseRoutes) {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const baseQuery = route.match(pathname);
		if (baseQuery) {
			const query = asQuery
				? {
						...asQuery,
						...baseQuery,
					}
				: baseQuery;
			return {
				route: {
					...route,
					pathname: route.pathname ?? route.destination,
				},
				query,
			};
		}
	}
};
