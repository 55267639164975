import { deprecatedMakeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

const useStyles = deprecatedMakeStyles<void, 'title' | 'subTitle' | 'uspContent'>()((theme, _, classes) => ({
	usp: {
		display: 'flex',
		fontFamily: theme.typography.fontFamily,
		fontWeight: theme.typography.fontWeightBold as number,
		padding: theme.spacing(1.25, 0),
	},
	title: {
		fontFamily: theme.typography.subtitle1.fontFamily,
		fontSize: theme.typography.subtitle1.fontSize,
		fontWeight: 400,
		display: 'inline-flex',
		alignItems: 'center',
	},
	variantHeader: {
		color: theme.palette.common.white,
		alignItems: 'center',

		[`& .${classes.title}`]: {
			paddingLeft: theme.spacing(0.25),
			marginLeft: theme.spacing(0.25),
			fontSize: '12px',
			fontWeight: theme.typography.fontWeightBold,

			[theme.breakpoints.up('sm')]: {
				fontSize: '14px',
			},
		},
	},
	variantPrimary: {
		color: theme.palette.primary.main,
		alignItems: 'center',

		[`& .${classes.title}`]: {
			paddingLeft: theme.spacing(1.75),
			fontWeight: 700,
		},
	},
	variantPriceBar: {
		color: theme.palette.common.black,
		alignItems: 'center',

		[`& .${classes.title}`]: {
			paddingLeft: theme.spacing(1),
		},
	},
	icon: {
		height: theme.spacing(2),
		width: theme.spacing(2),
	},
	variantFooter: {
		color: '#fff',
		marginLeft: '1px',

		[`& .${classes.uspContent}`]: {
			color: '#fff',
			marginLeft: theme.spacing(1.75),
		},
		[`& .${classes.title}`]: {
			fontWeight: 400,
			lineHeight: 1.25,
		},
		[`& .${classes.subTitle}`]: {
			color: '#fff',
			marginTop: theme.spacing(0.5),
			paddingLeft: 0,
			lineHeight: 1.54,
			fontWeight: 400,
		},
	},
	uspContent: {},
	subTitle: {},
}));

export default useStyles;
